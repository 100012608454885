"use client";

import { useState } from "react";
import { RetroGrid } from "../components/magicui/retro-grid";
import webBanner from "./../assets/bigrummy-web-image.png";
import Popup from "../components/popup-download";
import { useDownload } from "../lib/downloadUtils";

export function RetroGridDemo() {
  const { onDownload } = useDownload();
  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = async () => {
    const result = await onDownload();
    if (result === "popup") {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="md:mt-0 mt-10 relative flex h-[300px] md:h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background md:shadow-xl pb-36">
      <img
        src={webBanner}
        alt="Rummy"
        className="w-[100%] md:w-[50%] z-10 select-none cursor-pointer"
        onClick={handleDownloadClick}
      />
      <RetroGrid />
      {showPopup && <Popup onClose={handleClosePopup} />}
    </div>
  );
}
