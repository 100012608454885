import AlphaVideo from "./alphaVideo";
import gameAnim from ".././assets/gameAnim.mp4";
import gameAnimM from ".././assets/gameAnimM.mp4";
import rayw from ".././assets/ray.webm";
import pak555 from ".././assets/pak555_logo.png";
import best from ".././assets/best.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const MainSection = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center flex-col pt-20">
      <div className="flex items-center justify-center relative z-10 animate-scale">
        <AlphaVideo
          webmSrc={rayw}
          mp4Src={rayw}
          loop={true}
          width="600"
          height="500"
          className="absolute filter brightness-0 invert opacity-30"
        />
        <div className="w-[300px] h-[400-px] md:w-[600px] md:h-[500-px] flex justify-center items-center">
          <img
            src={pak555}
            alt="Pak555"
            className="relative"
            width="400"
            height="400"
          />
        </div>
      </div>
      <h1 className="mt-5 text-white md:text-4xl font-bold font-custom z-10 text-2xl text-center">
        {t("playBig")}
      </h1>
      <p className="mt-2 text-white text-xl font-custom z-10 text-center mx-10 opacity-70">
        {t("twentyFiveGames")}
      </p>

      <div className="relative flex flex-col justify-center overflow-hidden bg-slate-900 font-sans rounded-2xl mt-3">
        <div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>

        <div className="relative z-10 items-center overflow-hidden rounded-2xl border border-slate-800 p-[1.5px]">
          <div className="animate-rotate absolute inset-0 rounded-full bg-[conic-gradient(#0ea5e9_20deg,transparent_120deg)]"></div>
          <div className="relative z-20 flex w-full rounded-2xl bg-slate-900">
            {isMobile ? (
              <video
                width={380}
                autoPlay
                loop={true}
                muted
                playsInline
                className="rounded-2xl"
              >
                <source src={gameAnimM} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                width={1000}
                height={500}
                autoPlay
                loop={true}
                muted
                playsInline
                className="rounded-2xl"
              >
                <source src={gameAnim} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>

      <img
        src={best}
        alt={t("indiasBest")}
        className="object-cover h-20 w-50 mt-5 z-10 mx-auto animate-scale"
      />
    </div>
  );
};

export default MainSection;
