import NumberTicker from "./magicui/number-ticker";
import GradualSpacing from "./magicui/gradual-spacing";
import {IconCloudDemo} from "./cloud";
import {useTranslation} from "react-i18next";
import bank from "./../assets/bank1.png";
import easy from "./../assets/easy1.png";
import jazz from "./../assets/jazz1.png";
import payFrame from "./../assets/pay-frame.png";

const CloudSection = () => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center justify-center gap-5">
        <div className="flex flex-col items-center md:items-center justify-start w-[330px] md:w-[400px] md:mt-0 mt-10">
          <GradualSpacing
            text={t("winningsDispersed")}
            className="text-amber-500 text-xl font-custom text-center md:text-left font-bold"
          />
          <NumberTicker
            value={18574125}
            className="text-white text-5xl md:text-7xl font-custom text-center font-bold tracking-tight	"
            suffix="Rs."
          />
        </div>
        <div className="w-[500px] md:w-[400px] pointer-events-none md:mt-0 -mt-16">
          <IconCloudDemo />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="relative flex flex-col items-center justify-center mb-16 gap-4 bg-radial-purple md:mx-0 mx-12 md:w-[1100px] py-10 rounded-2xl">
          <img
            src={payFrame}
            alt="Pay Frame"
            className="absolute left-8 md:left-0 transform -translate-x-1/2 top-3/4 md:top-1/2 -translate-y-1/2 h-[250px] md:h-[300px] scale-x-[-1]"
          />
          <img
            src={payFrame}
            alt="Pay Frame"
            className="absolute right-8 md:right-0  transform translate-x-1/2 top-3/4 md:top-1/2 -translate-y-1/2 h-[250px] md:h-[300px]"
          />
          <GradualSpacing
            text={t("paymentMethods")}
            className="text-amber-500 text-xl font-custom text-center md:text-left font-bold"
          />
          <div className="text-white text-2xl md:text-4xl font-custom text-center font-bold max-w-[800px] items-center justify-center">
            {t("choosePayment")}
          </div>
          <div className="flex gap-2 md:flex-row flex-col items-center justify-center">
            <img
              src={bank}
              alt="Bank"
              className="h-[90px] md:h-[90px] transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={easy}
              alt="Easy"
              className="h-[90px] md:h-[90px] transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={jazz}
              alt="Jazz"
              className="h-[90px] md:h-[90px] transform transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudSection;
