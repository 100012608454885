"use client";

import { AnimatePresence, motion, Variants } from "framer-motion";
import GraphemeSplitter from "grapheme-splitter";

import { cn } from "../../lib/utils";

interface GradualSpacingProps {
  text: string;
  duration?: number;
  delayMultiple?: number;
  framerProps?: Variants;
  className?: string;
}

export default function GradualSpacing({
  text,
  duration = 0.2,
  delayMultiple = 0.02,
  framerProps = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  },
  className
}: GradualSpacingProps) {
  const splitter = new GraphemeSplitter();
  const graphemes = splitter.splitGraphemes(text);

  return (
    <div className="flex justify-center flex-wrap">
      <AnimatePresence>
        {graphemes.map((grapheme, i) => (
          <motion.span
            key={i}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={framerProps}
            transition={{ duration, delay: i * delayMultiple }}
            className={cn("drop-shadow-sm ", className)}
          >
            {grapheme === " " ? <span>&nbsp;</span> : grapheme}
          </motion.span>
        ))}
      </AnimatePresence>
    </div>
  );
}
