import { isIOS, isSafari } from "react-device-detect";

const AlphaVideo = ({ webmSrc, mp4Src, width, height, loop, className }) => {
  return (
    <video
      width={width}
      height={height}
      autoPlay
      loop={loop}
      muted
      playsInline
      style={{ backgroundColor: "transparent" }}
      className={`${className}`}
    >
      {isIOS || isSafari ? (
        <source src={mp4Src} type="video/mp4" />
      ) : (
        <source src={webmSrc} type="video/webm" />
      )}
      Your browser does not support the video tag.
    </video>
  );
};

export default AlphaVideo;
