import scv1 from "./../assets/videos/sc1.mp4";
import scv2 from "./../assets/videos/sc2.mp4";
import scv3 from "./../assets/videos/sc3.mp4";
import scv4 from "./../assets/videos/sc4.mp4";
import scv5 from "./../assets/videos/sc5.mp4";
import scv6 from "./../assets/videos/sc6.mp4";
import frame from "./../assets/frame.png";

const items = [scv1, scv3, scv2, scv5, scv4, scv6];

export function ScreenSection() {
  return (
    <div className="flex justify-center mt-5">
      <div className="w-full max-w-[1400px] p-2">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {items.map((item, index) => (
            <div
              key={index}
              className="relative w-[380px] h-[auto] rounded-3xl shadow-xl shadow-blue-600 transition-transform duration-300 ease-in-out transform hover:scale-105"
            >
              <video
                width={380}
                autoPlay
                loop={true}
                muted
                playsInline
                className="rounded-3xl py-1"
              >
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <img
                src={frame}
                alt="Frame"
                className="absolute inset-0 w-full h-full object-cover pointer-events-none"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
