"use client";

import React, {forwardRef, useRef} from "react";

import {cn} from "../lib/utils";
import {AnimatedBeam} from "../components/magicui/animated-beam";
import {isMobile} from "react-device-detect";

import {CoolMode} from "./magicui/cool";

import img0 from "../assets/avatars/0.png";
import img1 from "../assets/avatars/1.png";
import img2 from "../assets/avatars/2.png";
import img3 from "../assets/avatars/3.png";
import img4 from "../assets/avatars/4.png";
import img5 from "../assets/avatars/5.png";
import img6 from "../assets/avatars/6.png";

const Circle = forwardRef<
  HTMLDivElement,
  {className?: string; children?: React.ReactNode}
>(({className, children}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-16 items-center justify-center rounded-full shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]  overflow-hidden",
        className
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export function AnimatedBeamDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex h-[380px] w-full items-center justify-center overflow-hidden rounded-lg  bg-background md:p-10 -mt-20 select-none"
      ref={containerRef}
    >
      <div className="flex size-full flex-col max-w-lg max-h-[200px] items-stretch justify-between gap-1">
        <div className="flex flex-row items-center justify-between  px-10">
          <Circle ref={div1Ref}>
            <img src={img1} alt="Big Rummy" className="size-16 rounded-xlg" />
          </Circle>
          <Circle ref={div5Ref}>
            <img src={img2} alt="Big Rummy" className="size-16 rounded-xlg " />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div2Ref}>
            <img src={img3} alt="Big Rummy" className="size-16 rounded-xlg " />
          </Circle>
          <Circle
            ref={div4Ref}
            className="size-50 hover:scale-110 cursor-pointer transition-transform duration-300"
          >
            <img
              src={img0}
              alt="Big Rummy"
              className="size-50 rounded-xlg"
              width={100}
            />
          </Circle>

          <Circle ref={div6Ref}>
            <img src={img5} alt="Big Rummy" className="size-16 rounded-xlg " />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between px-10">
          <Circle ref={div3Ref}>
            <img src={img4} alt="Big Rummy" className="size-16 rounded-xlg " />
          </Circle>
          <Circle ref={div7Ref}>
            <img src={img6} alt="Big Rummy" className="size-16 rounded-xlg " />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
        reverse
      />
    </div>
  );
}
