"use client";

import {useEffect, useMemo, useState} from "react";
import {useTheme} from "next-themes";
import {Cloud, ICloud} from "react-icon-cloud";

export const cloudProps: Omit<ICloud, "children"> = {
  containerProps: {
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: 40
    }
  },
  options: {
    reverse: true,
    depth: 1,
    wheelZoom: false,
    imageScale: 0.8,
    activeCursor: "default",
    tooltip: "native",
    initial: [0.1, -0.1],
    clickToFront: 500,
    tooltipDelay: 0,
    outlineColour: "#0000",
    maxSpeed: 0.04,
    minSpeed: 0.02
  }
};

export type DynamicCloudProps = {
  imagePaths: string[];
};

export default function ImageCloud({imagePaths}: DynamicCloudProps) {
  const {theme} = useTheme();

  const renderedImages = useMemo(() => {
    return imagePaths.map((path, index) => (
      <a key={index} href="#" onClick={(e) => e.preventDefault()}>
        <img
          src={path}
          alt={`Image ${index}`}
          style={{width: 42, height: 42}}
        />
      </a>
    ));
  }, [imagePaths, theme]);

  return <Cloud {...cloudProps}>{renderedImages}</Cloud>;
}
