import IconCloud from "./magicui/icon-cloud";
import img1 from "../assets/icons/1.png";
import img2 from "../assets/icons/2.png";
import img3 from "../assets/icons/3.png";
import img4 from "../assets/icons/4.png";
import img5 from "../assets/icons/5.png";
import img6 from "../assets/icons/6.png";
import img7 from "../assets/icons/7.png";
import img8 from "../assets/icons/8.png";
import img9 from "../assets/icons/9.png";
import img10 from "../assets/icons/10.png";
import img11 from "../assets/icons/11.png";
import img12 from "../assets/icons/12.png";
import img13 from "../assets/icons/13.png";
import img14 from "../assets/icons/14.png";
import img15 from "../assets/icons/15.png";
import img16 from "../assets/icons/16.png";
import img17 from "../assets/icons/17.png";
import img18 from "../assets/icons/18.png";
import img19 from "../assets/icons/19.png";
import img20 from "../assets/icons/20.png";
import img21 from "../assets/icons/21.png";
import img22 from "../assets/icons/22.png";
import img23 from "../assets/icons/23.png";
import img24 from "../assets/icons/24.png";
import img25 from "../assets/icons/25.png";
import img26 from "../assets/icons/26.png";
import img27 from "../assets/icons/27.png";
import img28 from "../assets/icons/28.png";
import img29 from "../assets/icons/29.png";
import img30 from "../assets/icons/30.png";
import img31 from "../assets/icons/31.png";
import img32 from "../assets/icons/32.png";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32
];

export function IconCloudDemo() {
  return (
    <div className="relative flex h-full w-full max-w-[1400px] items-center justify-center overflow-hidden rounded-lg bg-background px-20 pb-20 pt-8">
      <IconCloud imagePaths={images} />
    </div>
  );
}
